import React from 'react'
import line from '../img/line.png';
import profile from '../img/banner/21.jpg'
const Allpages = () => {
	return (
		<div className="home">
			<div className="container">
				<div className="row">
					<div className="col-lg-8">
						<div className="profile-conntant">
							<h1 class="text-uppercase poppins-font" id="name-12">
								<img src={line} alt="line" className="line" />
								I'm Abhinaw Kumar Verma.<span>web designer</span>
							</h1>
							{/* <h1 className="webD">
								<span>web designer</span>
							</h1> */}
							<div className="p-text">
								<p class="open-sans-font">
									I'm a web designer &amp; front‑end developer focused on
									crafting clean &amp; user‑friendly experiences, I am
									passionate about building excellent software and web design
									that improves the lives of those around me.
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="profile-img">
							<img src={profile} alt="Profile img" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Allpages