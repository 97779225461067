import React from 'react'

const Contact = () => {
	return (
		<div className="contact-f">
			<div className="portfolio">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1 className="get-in">
								get in <span className="touch">touch</span>
							</h1>
							<span class="contact-page">contact</span>
						</div>
					</div>
				</div>
			</div>
			<div className="contact-f">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							{/* <div className="email-icon">
								<FaEnvelopeOpenText />
							</div> */}
							<p className="email">
								<span className="d-block">MAIL ME</span> abhinaw.dev52@gmail.com
							</p>
							<p className="email">
								<span className="d-block">Call ME</span> +91 900 660 5034
							</p>
						</div>
						<div className="col-lg-8">
							<div className="form-p">
								<form
									class="contactform"
									action="https://formspree.io/f/mgegdazz"
									method="POST"
								>
									<div class="contactform">
										<div class="row">
											<div class="col-12 col-md-4">
												<input
													type="text"
													name="name"
													placeholder="YOUR NAME"
													className="input-box"
												/>
											</div>
											<div class="col-12 col-md-4">
												<input
													type="email"
													name="email"
													placeholder="YOUR EMAIL"
													className="input-box"
												/>
											</div>
											<div class="col-12 col-md-4">
												<input
													type="text"
													name="subject"
													placeholder="YOUR SUBJECT"
													className="input-box"
												/>
											</div>
											<div class="col-12">
												<textarea
													name="message"
													placeholder="YOUR MESSAGE"
													className="text-box"
												></textarea>
												<button type="submit" className="button" name="submit">
													<span class="button-text">Send Message</span>
													<span class="button-icon fa fa-send"></span>
												</button>
											</div>
											<div class="col-12 form-message">
												<span class="output_message text-center font-weight-600 text-uppercase"></span>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		//https://formspree.io/f/mgegdazz
	);
}

export default Contact