import React, { useState }  from 'react'
import { Outlet, Link } from 'react-router-dom';
import { FaAlignJustify,FaAngleDown  } from "react-icons/fa";
import '../componets/navbar.css';

const Navbar = () => {
	const [isMobile, setIsMobile] = useState(false);
	return (
		<>
			<nav className="navbar">
				<Link to="/" id="top__logo">
					<h3 className="logo">Abhinaw Kumar Verma ( Portfolio)</h3>
				</Link>
				<ul
					className={isMobile ? 'nav-link-mobile' : 'nav-link'}
					onClick={() => setIsMobile(false)}
				>
					<Link to="/" className="nav-c">
						Home
					</Link>
					<Link to="/about" className="nav-c">
						About
					</Link>
					<Link to="/portfolio" className="nav-c">
						Portfolio
					</Link>
					<Link to="/contact" className="nav-c">
						Contact
					</Link>
				</ul>
				<button
					className="mobile-menu-icon"
					onClick={() => setIsMobile(!isMobile)}
				>
					{isMobile ? <FaAngleDown /> : <FaAlignJustify />}
				</button>
			</nav>
			<Outlet />
		</>
	);
}

export default Navbar