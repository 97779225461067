import React from 'react'
import { FaBriefcase, FaGraduationCap } from 'react-icons/fa';


const About = () => {
	return (
		<div className="about">
			<div className="aboutme">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1>
								About <span>Me</span>
							</h1>
							<span class="title-bg">Resume</span>
						</div>
					</div>
				</div>
			</div>
			<div className="skills">
				<div className="container">
					<div className="row">
						<div className="profileinfo"></div>
						<div className="col-12 col-lg-5 col-xl-6">
							<div className="row">
								<div className="col-6">
									<div className="profile">
										<h2>PERSONAL INFOS</h2>
										<ul class="about-list list-unstyled open-sans-font">
											<li>
												{' '}
												<span class="title">first name :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													Abhinaw Kumar
												</span>{' '}
											</li>
											<li>
												{' '}
												<span class="title">last name :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													Verma
												</span>{' '}
											</li>
											<li>
												{' '}
												<span class="title">Age :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													27 Years
												</span>{' '}
											</li>
											<li>
												{' '}
												<span class="title">Nationality :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													Indian
												</span>{' '}
											</li>
											<li>
												{' '}
												<span class="title">Freelance :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													Available
												</span>{' '}
											</li>
										</ul>
									</div>
								</div>
								<div className="col-6">
									<div className="profile">
										<ul class="about-list list-unstyled open-sans-font">
											<li>
												{' '}
												<span class="title">Address :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													Bangalore
												</span>{' '}
											</li>
											<li>
												{' '}
												<span class="title">Phone :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													+91 900 660 5034
												</span>{' '}
											</li>
											<li>
												{' '}
												<span class="title">Email :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													abhinaw.dev52@gmail.com
												</span>{' '}
											</li>
											<li>
												{' '}
												<span class="title">Langages :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													Hindi, English
												</span>{' '}
											</li>
											{/* <li>
												{' '}
												<span class="title">Freelance :</span>{' '}
												<span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
													Available
												</span>{' '}
											</li> */}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-7 col-xl-6 mt-5 mt-lg-0">
							<div class="row">
								<div class="col-6">
									<div class="box-stats with-margin">
										<h3 class="poppins-font position-relative">30</h3>
										<p class="open-sans-font m-0 position-relative text-uppercase">
											months of <span class="d-block">experience</span>
										</p>
									</div>
								</div>
								<div class="col-6">
									<div class="box-stats with-margin">
										<h3 class="poppins-font position-relative">15</h3>
										<p class="open-sans-font m-0 position-relative text-uppercase">
											completed <span class="d-block">projects</span>
										</p>
									</div>
								</div>
								<div class="col-6">
									<div class="box-stats">
										<h3 class="poppins-font position-relative">20</h3>
										<p class="open-sans-font m-0 position-relative text-uppercase">
											Happy<span class="d-block">customers</span>
										</p>
									</div>
								</div>
								<div class="col-6" id="box-hight">
									<div class="box-stats" id="box-hight1">
										<h3 class="poppins-font position-relative">5</h3>
										<p class="open-sans-font m-0 position-relative text-uppercase">
											Freelance{' '}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr class="separator" />
			<div className="ProgressBar">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1 className="myskills">MY SKILLS</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-3 pb-20 ">
							<p className="skillstitle">HTML</p>
							<div class="containerskill">
								<div class="skill html">89%</div>
							</div>
						</div>
						<div className="col-3 pb-20 ">
							<p className="skillstitle">css</p>
							<div class="containerskill">
								<div class="skill css">69%</div>
							</div>
						</div>

						<div className="col-3 pb-20 ">
							<p className="skillstitle">bootstrap</p>
							<div class="containerskill">
								<div class="skill bootstrap">80%</div>
							</div>
						</div>
						<div className="col-3 pb-20 ">
							<p className="skillstitle">javascript</p>
							<div class="containerskill">
								<div class="skill javascript">70%</div>
							</div>
						</div>
						<div className="col-3 pb-20 ">
							<p className="skillstitle">PHP</p>
							<div class="containerskill">
								<div class="skill php">30%</div>
							</div>
						</div>
						<div className="col-3 pb-20 ">
							<p className="skillstitle">WORDPRESS</p>
							<div class="containerskill">
								<div class="skill wordpress">60%</div>
							</div>
						</div>
						<div className="col-3 pb-20 ">
							<p className="skillstitle">JQUERY</p>
							<div class="containerskill">
								<div class="skill jquery">50%</div>
							</div>
						</div>
						<div className="col-3 pb-20 ">
							<p className="skillstitle">React js</p>
							<div class="containerskill">
								<div class="skill React">40%</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr class="separator" />
			<div className="exp_edu">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1 className="myskills">EXPERIENCE & EDUCATION</h1>
						</div>
						<div class="col-lg-6 m-15px-tb">
							<div class="resume-box">
								<ul>
									<li>
										<div class="icon">
											<FaBriefcase className="faicon" />
										</div>
										<span class="time open-sans-font text-uppercase">
											1st-oct-20 - Present
										</span>
										<h5 class="poppins-font text-uppercase">
											Web Developer{' '}
											<span class="place open-sans-font">Hostcats PVT LTD</span>
										</h5>
										<p class="open-sans-font">
											Working as Front-end Developer in Hostcats(seeknext) Pvt
											ltd company. Freelance supporting a start-up company to
											develop its product and e-commerce website. Web designing
											service globally.{' '}
										</p>
									</li>
									{/* Add freelancer projects */}

									<li>
										<div class="icon">
											<FaBriefcase className="faicon" />
										</div>
										<span class="time open-sans-font text-uppercase">
											1st-April-24 to 20st April-24
										</span>
										<h5 class="poppins-font text-uppercase">
											Web Developer{' '}
											<span class="place open-sans-font">
												Triple A Elevator (freelancer)
											</span>
										</h5>
										<p class="open-sans-font">
											Triple A Elevator is likely a company that specializes in
											elevator installation, maintenance, and repair services.
											Companies like this typically offer a range of services to
											ensure that elevators operate safely and efficiently.
										</p>
									</li>
									<li>
										<div class="icon">
											<FaBriefcase className="faicon" />
										</div>
										<span class="time open-sans-font text-uppercase">
											1st-May-24 to 20st May-24
										</span>
										<h5 class="poppins-font text-uppercase">
											Web Developer{' '}
											<span class="place open-sans-font">Stock-Sollution</span>
										</h5>
										<p class="open-sans-font">
											Stock-Sollution is India's best stock learning platform,
											making it possible for users around the nation to grasp
											the stock market skills with an ease of choices. We create
											financial value in the lives of people by delivering our
											services with a focus on positive growth.
										</p>
									</li>

									{/* Add freelancer projects */}
									<li>
										<div class="icon">
											<FaBriefcase className="faicon" />
										</div>
										<span class="time open-sans-font text-uppercase">
											1st-jun-20 to 1st sep-20
										</span>
										<h5 class="poppins-font text-uppercase">
											Web Developer{' '}
											<span class="place open-sans-font">
												skystechnology Pvt ltd
											</span>
										</h5>
										<p class="open-sans-font">
											Skys News is an Online news Website where we get the
											Sources from the top news websites which gets updated
											automatically after certain hours set by us, Designed and
											Developed by SkysTechnology
										</p>
									</li>
									<li>
										<div class="icon">
											<FaBriefcase className="faicon" />
										</div>
										<span class="time open-sans-font text-uppercase">
											1st Jan 2019 – 1st April 2019 - Internship
										</span>
										<h5 class="poppins-font text-uppercase">
											{' '}
											Online Collaboration Platform{' '}
											<span class="place open-sans-font">
												Aavid Knowledge Management Services Pvt ltd
											</span>
										</h5>
										<p class="open-sans-font">
											A Collaboration platform is a category of business
											software that adds broad social networking capabilities to
											work processes.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-lg-6 m-15px-tb">
							<div class="resume-box">
								<ul>
									<li>
										<div class="icon">
											<FaGraduationCap className="faicon" />
										</div>
										<span class="time open-sans-font text-uppercase">
											2017 to 2019
										</span>
										<h5 class="poppins-font text-uppercase">
											Master Degree (MCA)
											<span class="place open-sans-font">
												REVA University, Bengaluru (76.3%)
											</span>
										</h5>
									</li>
									<li>
										<div class="icon">
											<FaGraduationCap className="faicon" />
										</div>
										<span class="time open-sans-font text-uppercase">
											2011 to 2014
										</span>
										<h5 class="poppins-font text-uppercase">
											Bachelor Degree(BCA)
											<span class="place open-sans-font">
												BRAB University, Muzaffarpur (Bihar) (71.63%)
											</span>
										</h5>
									</li>

									<li>
										<div class="icon">
											<FaGraduationCap className="faicon" />
										</div>
										<span class="time open-sans-font text-uppercase">2011</span>
										<h5 class="poppins-font text-uppercase">
											10+2{' '}
											<span class="place open-sans-font">
												BSEB Patna (Bihar) (64.8%)
											</span>
										</h5>
									</li>
									<li>
										<div class="icon">
											<FaGraduationCap className="faicon" />
										</div>
										<span class="time open-sans-font text-uppercase">
											1st Dec 2024 – 31st Dec 2024
										</span>
										<h5 class="poppins-font text-uppercase"> Certifications</h5>
										<p class="open-sans-font">
											Web Design and Development in Infosys Springboard.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About