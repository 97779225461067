import React from 'react'
//import banner from '../img/banner/banner.jpg';
import Allpages from './Allpages';
const Home = () => {
	return (
		<>
			<div className='homepage'>
            {/* <div className="banner">
                <img src={banner} alt='banner' />
            </div> */}
           <Allpages />
		</div>
		</>
	);
}

export default Home